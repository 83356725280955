
<template>
  <div class="shop-member-time dp_f">
    <member-time-str :time="baseInfo?.outTime"></member-time-str>
    <button class="wy-button primary" @click="goIndex">{{ baseInfo.outTime ? '立即续费' : '购买套餐' }}</button>
  </div>
</template>

<script setup>
import MemberTimeStr from './time.vue'
import {getCurrentInstance, toRefs} from "vue";
import {useRoute} from "vue-router";
import {goSomeWhere} from '@views/shop/utils';

const props = defineProps({
  baseInfo: {
    type: Object,
    default: () => {
    },
  }
})
const {baseInfo} = toRefs(props)
const route = useRoute();
const {proxy} = getCurrentInstance();
const goIndex = () => {
  let path  =  baseInfo.value.outTime ? '/shop/order/renewal/2' : '/shop/order/buy/1'
  goSomeWhere(route, path)
}
</script>
<script>
import {defineComponent} from "vue";
export default defineComponent({
  name: "memberTime",
});
</script>
<style lang="scss" src='../../index/index.scss' scoped>
</style>